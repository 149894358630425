import React from 'react';
import './bg.css';

const Bg = () =>
{
    return(
<div className='homebackground'>
    <div className='backopacity'></div>
</div>
    )
}

export default Bg