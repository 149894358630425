import NavBar from "../../component/navbar/Navbar.jsx"
import Festival from "../../component/festival/festival.jsx"
import Background from "../../component/backfestival/bgfestival.jsx"
const home =() =>{

    return(
    <>
    <NavBar />
    <Background/>
    <Festival />
    </>

    )

}

export default home