import RegisterPage2 from "../../component/specialdevotee/specialdevotee";
import Welcome from "../../component/welcome/welcome";

const Special = () => {
    return (
        <>
            <RegisterPage2 />
            <Welcome />
        </>
    );
};

export default Special;
