import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Form, Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import * as XLSX from 'xlsx';

const Volunteerview = () => {
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [userDetails, setUserDetails] = useState(null);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSearch = async () => {
        let query = '';
        if (phone) {
            query = `phone=${phone}`;
        } else if (name) {
            query = `name=${name}`;
        } else {
            setError('Please enter a phone number or name to search.');
            return;
        }

        try {
            const response = await axios.get(`https://venkatesaperumal-backend.onrender.com/api/volunteer?${query}`);
            setUserDetails(response.data);
            setError('');
        } catch (error) {
            setError('User not found');
            setUserDetails(null);
        }
    };

    const handleRegisterNewDevotee = () => {
        navigate('/addvounteer');
    };

    const handleEdit = () => {
        if (userDetails) {
            navigate('/addvounteer', { state: { userDetails } });
        }
    };

    const handleDownload = async () => {
        try {
            const response = await axios.get('https://venkatesaperumal-backend.onrender.com/api/volunteer/alluser'); // Fetch all volunteers
            

            if (!response.data || response.data.length === 0) {
                setError('No data available to download.');
                return;
            }

            // Remove sensitive fields
            const filteredData = response.data.map(({ password, isAdmin,_id,createdAt,updatedAt,__v,  ...rest }) => rest);

            // Convert all volunteer details to Excel
            const worksheet = XLSX.utils.json_to_sheet(filteredData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Volunteers');

            // Generate buffer
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

            // Create a blob and trigger download
            const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'all_volunteers.xlsx');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            setError('Error downloading data');
        }
    };

    return (
        <div>
            <h2 className='heading'>Search Volunteer Devotee</h2>
            <Form>
                <Form.Group className="mb-3">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                        type="text"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="Enter phone number"
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Enter name"
                    />
                </Form.Group>
                <Button onClick={handleSearch}>Search</Button>
                <Button variant="link" onClick={handleRegisterNewDevotee}>Register New Volunteer</Button>
            </Form>
            {error && (
                <Row className="mt-3">
                    <Col>
                        <Alert variant="danger">{error}</Alert>
                    </Col>
                </Row>
            )}
            {userDetails && (
                <Card.Body className='carddisplay'>
                    <Card.Title className='text-center p-3 line'>Volunteer Details</Card.Title>
                    <Card.Text className='carddetails'>
                        <div className='details'>
                            Name: {userDetails.username}
                        </div>
                        <div className='details'>
                            Phone Number: {userDetails.phone}
                        </div>
                        <div className='details'>
                            Address: {userDetails.address}
                        </div>
                    </Card.Text>
                    <Button variant="outline-info" className='placebtn' onClick={handleEdit}>Edit</Button>
                </Card.Body>
            )}
            <div className="mt-3">
                <Button variant="outline-success" onClick={handleDownload}>Download All Volunteers</Button>
            </div>
        </div>
    );
};

export default Volunteerview;
