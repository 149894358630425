import Calendar from "../../component/eventscalender/Calendar.jsx";
import NavBar from "../../component/navbar/Navbar.jsx"

const Events = () => {
  return (
    <div>
      <NavBar/>
      <Calendar />

    </div>
  );
};

export default Events;