import NavBar from "../../component/navbar/Navbar.jsx"
import Backgroundhistory from "../../component/backhistory/bghistory.jsx"
import History from "../../component/history/history.jsx"
const historypage =() =>{

    return(
    <>

    <NavBar />
    <Backgroundhistory/>
    <History />
    </>
    )

}

export default historypage