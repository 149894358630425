import ContactUs from "../../component/contact/Contactus";
import NavBar from "../../component/navbar/Navbar.jsx";

const Contact =() =>{
    return(
        <>
          <NavBar />
        <ContactUs />
       

        </>
    )
}

export default Contact