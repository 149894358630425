import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './login.css';
import Button from 'react-bootstrap/Button'
function LoginPage() {
  const [phone, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword,setShowPassword]=useState(false)
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://venkatesaperumal-backend.onrender.com/api/auth/login', {
        phone,
        password,
      },{ withCredentials: true });
      // Assuming the response contains a user object
      localStorage.setItem('user', JSON.stringify(response.data.user));
      navigate('/');
    } catch (error) {
      console.error('Login failed:', error);
    }
  };
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className='logincenter'>
    <form className="form" onSubmit={handleLogin}>
      <p className="form-title">Login in to your account</p>
      <div className="input-container">
        <input
          type="text"
          placeholder="Enter phone number"
          value={phone}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        <span></span>
      </div>
      <div className="input-container">
        <input
          type={showPassword ? "text" : "password"}
          placeholder="Enter password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button variant="outline-info" onClick={toggleShowPassword}>
                  {showPassword ? "Hide" : "Show"}
                </Button>
      </div>
      <button type="submit" className="submit">
        Login
      </button>

      <p className="signup-link">
        No account?
        <a href="/signup">Sign up</a>
      </p>
    </form>
    </div>
  );
}

export default LoginPage;
