import LoginPage from "../../component/login/LoginPage";
import Welcome from "../../component/welcome/welcome";

const login =() =>{
    return(
        <>
        <LoginPage />
        <Welcome />
        </>
    )
}
export default login