import RegisterPage from "../../component/register/register"
import Welcome from "../../component/welcome/welcome";
const register =() =>{
    return(
        <>
        <RegisterPage />
        <Welcome />
        </>
    )
}
export default register