import NavBar from "../../component/navbar/Navbar.jsx"
import Banner from "../../component/banner/banner.jsx"
import Background from "../../component/background/Bg.jsx"
import Footer from "../../component/footer/Footer.jsx"

const home =() =>{

    return(
    <>
    <NavBar />
    <Background/>
    <Banner />
    <Footer/>
    </>

    )

}

export default home