import React from 'react';
import './banner.css';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import { useEffect } from 'react';
const Banner = () =>{
    
    const { t, i18n } = useTranslation(); // useTranslation hook
   
    useEffect(() => {
        // Get the saved language from localStorage
        const savedLanguage = localStorage.getItem('language') || 'en'; // Default to 'en' if not set
        if (i18n.language !== savedLanguage) {
          // Only change language if it's different from the current one
          i18n.changeLanguage(savedLanguage);
        }
      }, [i18n]); // Run only once when the component mounts

    return(
        <>
  <div className='container containerbanner'>
    <div className='row align-items-center'>
        <div className="col-md-3 col-sm-12 banner">
            <img src='https://res.cloudinary.com/dsgdnskfj/image/upload/v1725897309/perumaal_temple_ozlzf2.jpg' alt='Venkateshware' className='image_layout' />
       </div>
       <div className="col-md-9 col-sm-12  content">
        <h4 className='headcontent'>  {t('homeheading1')}</h4>
        <div className='line1'></div>
        <p>{t('homecontent1')}</p>  
        <p>{t('homecontent2')}</p>
       </div>
       </div>
       <br></br>
       <div className='row align-items-center'>
        
       <div className="col-md-9 col-sm-12  content">
        <h4 className='headcontent'>{t('homeheading2')}</h4>
        <div className='line1'></div>
        <p>{t('homecontent3')}</p>
       </div>
       <div className="col-md-3 col-sm-12 banner">
            <img src='https://res.cloudinary.com/dsgdnskfj/image/upload/v1725958329/perumaal_temple2_1_o1sl6g.jpg' alt='Venkateshware' className='image_layout' />
       </div>
       </div>
       </div>
       </>
       
    )
        
}
export default Banner;