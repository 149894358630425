import React from 'react';
import './bghistory.css';

const bghistory = () =>
{
    
    return(
<div className='homebackground1'>
    <div className='backopacity1'></div>
</div>
    )
}

export default bghistory